const copyToClipboard = (str: string) => {
  const el = document.createElement("textarea");
  el.value = str;
  document.body.appendChild(el);
  el.select();
  document.execCommand("copy");
  document.body.removeChild(el);
};

const start = () => {
  const buttonToken = document.querySelector("#token button");

  if (buttonToken) {
    buttonToken.addEventListener("click", () => {
      const tokenValue = document.querySelector("#token .value").innerHTML;
      copyToClipboard(tokenValue);
      showCopiedMessage();
    });
  }

  const nav = document.querySelector("header nav");
  const button = document.getElementById("menuButton");
  button.addEventListener("click", () => {
    const isOpen = nav.classList.contains("open");
    if (isOpen) {
      nav.classList.remove("open");
      button.classList.remove("open");
    } else {
      nav.classList.add("open");
      button.classList.add("open");
    }
  });

  Array.from(document.querySelectorAll("header nav a")).forEach((a) => {
    a.addEventListener("click", () => {
      nav.classList.remove("open");
      button.classList.remove("open");
    });
  });

  Array.from(document.querySelectorAll("header nav button")).forEach((e) => {
    e.parentElement.classList.add("group");
  });

  Array.from(document.querySelectorAll("header nav button")).forEach(
    (button) => {
      button.addEventListener("click", () => {
        Array.from(document.querySelectorAll("header nav button"))
          .filter((e) => e !== button)
          .forEach((e) => {
            e.parentElement.classList.remove("open");
          });
        const { parentElement } = button;
        if (parentElement.classList.contains("open")) {
          parentElement.classList.remove("open");
        } else {
          parentElement.classList.add("open");
        }
      });
    }
  );
};

const showCopiedMessage = () => {
  document.getElementById("copied").style.display = "block";
  setTimeout(hideCopiedMessage, 2000);
};

const hideCopiedMessage = () => {
  document.getElementById("copied").style.display = "none";
};

window.addEventListener("load", start);
